
import uiMixin from '../mixins/ui';

export default {
  name: 'ObjectiveButton',
  mixins: [
    uiMixin,
  ],
  computed: {
    objective() {
      return this.$store.getters['objectives/itemActive'];
    },
    label() {
      return this.objective
        ? this.objective.label
        : '';
    },
  },
  methods: {
    handleView() {
      if (this.objective) {
        this.$store.dispatch('ui/showModal', { name: 'objective', payload: { name: this.objective.name } });
      } else {
        this.$store.dispatch('ui/showModal', { name: 'objectivesList' });
      }
    },
  },
};
