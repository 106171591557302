
import { Resource } from '../api/src/models/Resource';
import uiMixin from '../mixins/ui';
import ResourceValueButton from '~/components/resource-value-button';

export default {
  name: 'ObjectiveAndLog',
  components: {
    ResourceValueButton,
  },
  mixins: [
    uiMixin,
  ],
  computed: {
    objective() {
      return this.$store.getters['objectives/itemActive'];
    },
    resources() {
      const resources = [];

      if (this.isDebugger) {
        resources.push(Resource.ResourceName.enum.Radia);

        // must not be undefined and greater than 0
        if (this.$store.getters['systems/getValueForUpgradeByName'](this.$store.state.systems.upgradeName.AetherCreation)) {
          resources.push(Resource.ResourceName.enum.Aether);
        }

        // must not be undefined and greater than 0
        if (this.$store.getters['systems/getValueForUpgradeByName'](this.$store.state.systems.upgradeName.ShadeCreation)) {
          resources.push(Resource.ResourceName.enum.Shade);
        }
      }

      return resources;
    },
    hasResources() {
      return this.isDebugger && this.resources.length > 0;
    },
  },
};
